import React from "react";

const valforimg = [
    {
        firstthree: [
            { imag: "/img/alarmanlagen-haus/1.1.jpg", ititle: "Zentrale" },
            { imag: "/img/alarmanlagen-haus/1.2.jpg", ititle: "Bedienteil" },
            { imag: "/img/alarmanlagen-haus/1.3.jpg", ititle: "Außensirene" },
        ],

        secondthree: [
            {
                imag: "/img/alarmanlagen-haus/1.4.jpg",
                ititle: "Innen-Bewegungsmelder",
            },
            {
                imag: "/img/alarmanlagen-haus/1.5.jpg",
                ititle: "Tür- und Fenstersensoren",
            },
            { imag: "/img/alarmanlagen-haus/1.6.png", ititle: "App Steuerung" },
        ],
    },
];

const Alarmlagencompbox = () => {
    return (
        <div>
            {valforimg.map((detail) => (
                <div className="columns is-multiline arrcol">
                    {detail.firstthree.map((ft) => (
                        <div className="column has-text-centered">
                            <div className="box">
                                <img
                                    className="imgsz"
                                    src={ft.imag}
                                    alt="image"
                                />
                            </div>
                            <div>
                                <p className="title plantext has-text-centered">
                                    {ft.ititle}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            ))}
            {valforimg.map((detail) => (
                <div className="columns is-multiline arrcol">
                    {detail.secondthree.map((st) => (
                        <div className="column has-text-centered">
                            <div className="box">
                                <img
                                    className="imgsz"
                                    src={st.imag}
                                    alt="image"
                                />
                            </div>
                            <div>
                                <p className="title plantext has-text-centered">
                                    {st.ititle}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};

export default Alarmlagencompbox;
