import React from 'react';

const valforimg=[

    {thirdthree:[{imag:"/img/alarmanlagen-haus/2.1.jpg",
    ititle:"Glasbruchmelder"},
    {imag:"/img/alarmanlagen-haus/2.2.jpg",
    ititle:"Rauchmelder"},
    {imag:"/img/alarmanlagen-haus/2.3.jpg",
    ititle:"Außen-Bewegungsmelder"}],

    fourththree:[{imag:"/img/alarmanlagen-haus/2.4.jpg",
    ititle:"Innensirene"},
    {imag:"/img/alarmanlagen-haus/2.5.jpg",
    ititle:"Überfallalarm-schalter"},
    {imag:"/img/alarmanlagen-haus/2.6.png",
    ititle:"Videoüber-wachungsanlage"}],
    }
];

const Alarmlagencompbox2 = () => {
    return (
        <div>
        {valforimg.map(detail =>
        <div className="columns is-multiline arrcol">
        {detail.thirdthree.map(tt =>
                    <div className="column has-text-centered">
                    <div className="box">
                    <img className="imgsz" src={tt.imag}
                                        alt="image"/>
                    </div><div>
                    <p className="title plantext has-text-centered">
                        {tt.ititle}
                    </p>
                    </div>
                    </div>
                )}
        </div> 
        )}
        {valforimg.map(detail =>
        <div className="columns is-multiline arrcol">
        {detail.fourththree.map(frt =>
                    <div className="column has-text-centered">
                    <div className="box">
                    <img className="imgsz" src={frt.imag}
                                        alt="image"/>
                    </div><div>
                    <p className="title plantext has-text-centered">
                        {frt.ititle}
                    </p>
                    </div>
                    </div>
                )}
        </div> 
        )}
        </div>
    );
};


export default Alarmlagencompbox2;