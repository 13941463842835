import React from "react";
import Alarmlagencompbox from "./Alarmlagencompbox";
import Alarmlagencompbox2 from "./Alarmlagencompbox2";
import PriceCalculationButton from "./PriceCalculator/PriceCalculationButton";
import { Link } from "gatsby";

const details = [
    // { cstyle1: "container-l has-text-centered" },
    // {
    //     cstyle1: "container-r bg-color has-text-centered",
    //     title: "Welche Komponenten sind für eine zuverlässige Alarmanlage für ein Einfamilienhaus erforderlich?",
    //     description: <Alarmlagencompbox />,
    // },
    // {
    //     cstyle1: "container2-l has-text-centered",
    //     title: "Welche zusätzlichen Komponenten erweitern die Sicherheit einer Alarmanlage für ein Wohnhaus?",
    //     description: <Alarmlagencompbox2 />,
    // },
    // {
    //     cstyle1: "container2-r has-text-centered",
    //     description: <Buttons1 className="btnbottom" />,
    // },
    {
        cstyle1: "container3-l has-text-centered",
        description: <Buttons2 className="btnbottom" />,
    },
    {
        cstyle1: "container3-r bg-color has-text-centered",
        title: "Wie wird ein Ladengeschäft von MeinAlarm24 abgesichert?",
        description: (
            <div>
                Bei gewerblich genutzten Objekten mit Kundenbesuch – wie z.B.
                einem Laden - muss sichergestellt werden, dass nach Ladenschluss
                eine professionelle Alarmanlage scharfgeschaltet wird und sowohl
                der Innenraum als auch die Außenhaut gesichert sind. Weiterhin
                empfiehlt sich eine
                <Link to="/videoueberwachung/"> Videoüberwachungsanlage</Link>,
                um über alle Geschehnisse im eigenen Geschäft informiert zu sein
                und auch zu bleiben.
                <br />
                <br />
                Unsere modernen, optisch ansprechenden und technisch
                hochwertigen Bewegungsmelder werden an entscheidenden Stellen
                platziert und mit weiteren Komponenten, wie Glasbruchmelder,
                Magnetkontakte oder Sirenen ergänzt. Welche spezifischen
                Komponenten für Ihr Vorhaben notwendig und sinnvoll sind, prüfen
                unsere professionellen Sicherheitsberater beim kostenlosen
                Sicherheitscheck bei Ihnen vor Ort.
                {/*  */}
                <br />
                <br />
                <div className="bottom   has-text-centered">
                    <Link
                        className="button preisrechner-btn preisrechner-btn-primary"
                        to="/preisrechner"
                        id="btn_preisrechner_case1"
                    >
                        Jetzt Termin vereinbaren
                    </Link>
                </div>
            </div>
        ),
    },

    {
        cstyle1: "container4-l has-text-centered",
        title: "Wie werden Büroräume von MeinAlarm24 abgesichert?",
        description: (
            <div>
                Im Vergleich zu Läden und Geschäften sind Büroräume oft weniger
                exponiert und haben weniger häufig Besuch von Dritten. Dies
                macht die Gefahr eines Einbruches aber nicht geringer. Die
                spezifischen Anforderungen unterscheiden sich von denen eines
                Geschäfts oder einer Lagerhalle, wobei die zentralen Komponenten
                wie Bewegungsmelder, Sirenen, Magnetkontakte und Alarmzentrale
                bestehen bleiben.
                <br />
                <br />
                Die Steuerung der Alarmanlage kann dabei für verschiedene Nutzer
                freigeschaltet werden. In Kombination mit unseren{" "}
                <Link to="/schliessanlagen/">
                    {" "}
                    elektronischen Schließanlagen
                </Link>
                &nbsp; lassen sich auch unterschiedliche Zutrittsprofile für
                verschiedene Bereiche und Uhrzeiten für die einzelnen
                Mitarbeiter definieren. Unsere professionellen
                Sicherheitsberater beachten beim kostenlosen Sicherheitscheck
                bei Ihnen vor Ort alle Eventualitäten und entwickeln mit Ihnen
                gemeinsam ein passendes Sicherheitskonzept.
                <br />
                <br />
                <div className="bottom   has-text-centered">
                    <Link
                        className="button preisrechner-btn preisrechner-btn-primary"
                        to="/preisrechner"
                        id="btn_preisrechner_case2"
                    >
                        Jetzt Termin vereinbaren
                    </Link>
                </div>
            </div>
        ),
    },
    { cstyle1: "container4-r has-text-centered" },
    { cstyle1: "container-l has-text-centered" },

    {
        cstyle1: "container-r bg-color has-text-centered",
        title: "Welche Komponenten sind für eine zuverlässige Alarmanlage für ein Gewerbe erforderlich?",
        description: <Alarmlagencompbox />,
    },
    {
        cstyle1: "container2-l has-text-centered",
        title: "Welche zusätzlichen Komponenten erweitern die Sicherheit einer Alarmanlage für eine Firma?",
        description: <Alarmlagencompbox2 />,
    },
    {
        cstyle1: "container2-r has-text-centered",
        // description: <Buttons1 className="btnbottom" />,
    },
];
const detailsM = [
    // { cstyle1: "container-l has-text-centered" },
    // {
    //     cstyle1: "container-r bg-color has-text-centered",
    //     title: "Welche Komponenten sind für eine zuverlässige Alarmanlage für ein Einfamilienhaus erforderlich?",
    //     description: <Alarmlagencompbox />,
    // },
    // {
    //     cstyle1: "container2-l has-text-centered",
    //     title: "Welche zusätzlichen Komponenten erweitern die Sicherheit einer Alarmanlage für ein Wohnhaus?",
    //     description: <Alarmlagencompbox2 />,
    // },
    // {
    //     cstyle1: "container2-r has-text-centered",
    //     description: <Buttons1 className="btnbottom" />,
    // },
    {
        cstyle1: "container3-l has-text-centered",
        description: <Buttons2 className="btnbottom" />,
    },
    {
        cstyle1: "container3-r bg-color has-text-centered",
        title: "Wie wird ein Ladengeschäft von MeinAlarm24 abgesichert?",
        description: (
            <div>
                Bei gewerblich genutzten Objekten mit Kundenbesuch – wie z.B.
                einem Laden - muss sichergestellt werden, dass nach Ladenschluss
                eine professionelle Alarmanlage scharfgeschaltet wird und sowohl
                der Innenraum als auch die Außenhaut gesichert sind. Weiterhin
                empfiehlt sich eine
                <Link to="/videoueberwachung/"> Videoüberwachungsanlage</Link>,
                um über alle Geschehnisse im eigenen Geschäft informiert zu sein
                und auch zu bleiben.
                <br />
                <br />
                Unsere modernen, optisch ansprechenden und technisch
                hochwertigen Bewegungsmelder werden an entscheidenden Stellen
                platziert und mit weiteren Komponenten, wie Glasbruchmelder,
                Magnetkontakte oder Sirenen ergänzt. Welche spezifischen
                Komponenten für Ihr Vorhaben notwendig und sinnvoll sind, prüfen
                unsere professionellen Sicherheitsberater beim kostenlosen
                Sicherheitscheck bei Ihnen vor Ort.
                {/*  */}
                <br />
                <br />
                <div className="bottom   has-text-centered">
                    <Link
                        className="button preisrechner-btn preisrechner-btn-primary"
                        to="/preisrechner"
                        id="btn_preisrechner_case1"
                    >
                        Jetzt Termin vereinbaren
                    </Link>
                </div>
            </div>
        ),
    },
    { cstyle1: "container4-r has-text-centered" },

    {
        cstyle1: "container4-l has-text-centered",
        title: "Wie werden Büroräume von MeinAlarm24 abgesichert?",
        description: (
            <div>
                Im Vergleich zu Läden und Geschäften sind Büroräume oft weniger
                exponiert und haben weniger häufig Besuch von Dritten. Dies
                macht die Gefahr eines Einbruches aber nicht geringer. Die
                spezifischen Anforderungen unterscheiden sich von denen eines
                Geschäfts oder einer Lagerhalle, wobei die zentralen Komponenten
                wie Bewegungsmelder, Sirenen, Magnetkontakte und Alarmzentrale
                bestehen bleiben.
                <br />
                <br />
                Die Steuerung der Alarmanlage kann dabei für verschiedene Nutzer
                freigeschaltet werden. In Kombination mit unseren{" "}
                <Link to="/schliessanlagen/">
                    {" "}
                    elektronischen Schließanlagen
                </Link>
                &nbsp; lassen sich auch unterschiedliche Zutrittsprofile für
                verschiedene Bereiche und Uhrzeiten für die einzelnen
                Mitarbeiter definieren. Unsere professionellen
                Sicherheitsberater beachten beim kostenlosen Sicherheitscheck
                bei Ihnen vor Ort alle Eventualitäten und entwickeln mit Ihnen
                gemeinsam ein passendes Sicherheitskonzept.
                <br />
                <br />
                <div className="bottom   has-text-centered">
                    <Link
                        className="button preisrechner-btn preisrechner-btn-primary"
                        to="/preisrechner"
                        id="btn_preisrechner_case2"
                    >
                        Jetzt Termin vereinbaren
                    </Link>
                </div>
            </div>
        ),
    },

    { cstyle1: "container-l has-text-centered" },
    {
        cstyle1: "container-r bg-color has-text-centered",
        title: "Welche Komponenten sind für eine zuverlässige Alarmanlage für ein Gewerbe erforderlich?",
        description: <Alarmlagencompbox />,
    },
    {
        cstyle1: "container2-l has-text-centered",
        title: "Welche zusätzlichen Komponenten erweitern die Sicherheit einer Alarmanlage für eine Firma?",
        description: <Alarmlagencompbox2 />,
    },
    // {
    //     cstyle1: "container2-r has-text-centered",
    //     // description: <Buttons1 className="btnbottom" />,
    // },
];

// const detailsM = [
//     // { cstyle1: "container-l has-text-centered" },
//     // {
//     //     cstyle1: "container-r bg-color has-text-centered",
//     //     title: "Welche Komponenten sind für eine zuverlässige Alarmanlage für ein Einfamilienhaus erforderlich?",
//     //     description: <Alarmlagencompbox />,
//     // },
//     // {
//     //     cstyle1: "container2-r has-text-centered",
//     //     description: <Buttons1 className="btnbottom" />,
//     // },
//     // {
//     //     cstyle1: "container2-l has-text-centered",
//     //     title: "Welche zusätzlichen Komponenten erweitern die Sicherheit einer Alarmanlage für ein Wohnhaus?",
//     //     description: <Alarmlagencompbox2 />,
//     // },
//     {
//         cstyle1: "container3-l has-text-centered",
//         description: <Buttons2 className="btnbottom" />,
//     },
//     // {
//     //     cstyle1: "container3-r bg-color has-text-centered",
//     //     title: "Wie wird Ihr Einfamilienhaus von MeinAlarm24 abgesichert?",
//     //     description:
//     //         "Eine professionelle Alarmanlage - auch Einbruchmeldeanlage genannt - wird in verschiedene Schutzschichten unterteilt, um maximale Sicherheit zu erreichen. Die Zentrale agiert dabei als Gehirn einer Alarmanlage. Die weitere Absicherung erfolgt von Innen nach Außen in mindestens zwei Schutzschichten:",
//     //     description1: (
//     //         <ol className="spaces">
//     //             <li>
//     //                 Innenraum: Absicherung durch Innenbewegungsmelder zur
//     //                 Detektion von Bewegungen im Haus. Optional mit Fotokamera
//     //                 und tierimmun.
//     //             </li>
//     //             <li>
//     //                 Außenhaut: Im eigenen Haus sicher fühlen und durch Tür- und
//     //                 Fenstersensoren sowie Glasbruchmelder beim Aufhebeln einer
//     //                 Tür oder Einschlagen eines Fensters alarmiert werden
//     //             </li>
//     //             <li>
//     //                 Grundstück: Mit Hilfe von Außenbewegungsmelder sowie
//     //                 optional einer Videoüberwachungsanlage Bewegungen bereits
//     //                 vor dem Haus erfassen
//     //             </li>
//     //         </ol>
//     //     ),
//     //     description2:
//     //         "Über das Bedienteil oder via App können alle Schutzschichten separat von einander scharf und/oder unscharf geschaltet werden. Welche spezifischen Komponenten für Ihr Vorhaben notwendig und sinnvoll sind, prüfen unsere professionellen Sicherheitsberater beim kostenlosen Sicherheitscheck bei Ihnen vor Ort.",
//     // },
//     // { cstyle1: "container4-r has-text-centered" },
//     {
//         cstyle1: "container4-l has-text-centered",
//         title: "Was passiert im Falle eines Alarms in oder an meinem Haus?",
//         description:
//             "Grundsätzlich erfassen unsere Alarmsysteme unterschiedliche Arten von Alarmen, zu denen neben Einbruchalarm, Sabotagealarm und Überfallalarm auch Gefahrenalarme wie z.B. Brand-, Wasser-, oder Kohlenmonoxidalarm zählen.",
//         description1:
//             "Bei jeder dieser Arten erfolgt eine Alarmierung an verschiedenen Stellen, um für den Ernstfall gewappnet zu sein:",
//         description2: (
//             <ul className="spaces">
//                 <li>
//                     <b>Lokale Alarmierung:</b> Durch das Auslösen der Innen- und
//                     Außensirene werden Personen im Haus, aber auch Nachbarn
//                     alarmiert und der Einbrecher durch die akustischen Signale
//                     in die Flucht geschlagen
//                 </li>
//                 <li>
//                     <b>Alarmierung der Bewohner:</b> Weiterhin wird sofort eine
//                     Push-Benachrichtigung auf alle Smartphones gesendet, auf
//                     denen die App eingerichtet ist
//                 </li>
//                 <li>
//                     <b>Alarmierung zur Intervention:</b> Sofern die Alarmanlage
//                     aufgeschaltet ist, wird zusätzlich unsere Notrufleitstelle
//                     benachrichtigt, die dann einen festgelegten Plan verfolgt.
//                     Sollten Sie den Alarm nicht entschärfen, wird auch die
//                     Polizei oder unser Sicherheitsdienst informiert, um im
//                     Ernstfall keine Sekunde Zeit zu verlieren
//                 </li>
//             </ul>
//         ),
//     },

//     { cstyle1: "container-l has-text-centered" },
//     {
//         cstyle1: "container-r bg-color has-text-centered",
//         title: "Welche Komponenten sind für eine zuverlässige Alarmanlage für ein Gewerbe erforderlich?",
//         description: <Alarmlagencompbox />,
//     },
//     {
//         cstyle1: "container2-r has-text-centered",
//         // description: <Buttons1 className="btnbottom" />,
//     },
//     {
//         cstyle1: "container2-l has-text-centered",
//         title: "Welche zusätzlichen Komponenten erweitern die Sicherheit einer Alarmanlage für eine Firma?",
//         description: <Alarmlagencompbox2 />,
//     },
// ];

function Buttons1() {
    return (
        <div className="columns">
            <div className="column has-text-centered">
                <a
                    className="button preisrechner-btn preisrechner-btn-primary"
                    href="/beratungstermine/"
                >
                    Kostenloses Erstgespräch vereinbaren
                </a>
            </div>
        </div>
    );
}

function Buttons2() {
    return (
        <div className="columns">
            <div className="column has-text-centered">
                {/* Jetzt Termin vereinbaren */}
            </div>
        </div>
    );
}

function Containers(props) {
    return (
        <div className="column is-half">
            <div className={props.details.cstyle1}>
                <h2>{props.details.title}</h2>
                <div className="container">
                    <p className="description">
                        {props.details.description}
                        <br />
                        <br />
                        {props.details.description1}
                        <br />
                        {props.details.description2}
                    </p>
                </div>
            </div>
        </div>
    );
}

function ContainersM(props) {
    return (
        <div className="column is-half">
            <div className={props.detailsM.cstyle1}>
                <h2>{props.detailsM.title}</h2>
                <div className="container">
                    <p className="description">
                        {props.detailsM.description}
                        <br />
                        <br />
                        {props.detailsM.description1}
                        <br />
                        {props.detailsM.description2}
                    </p>
                </div>
            </div>
        </div>
    );
}

const AlarmanlagenFirmcomp = () => {
    return (
        <div className="alarmanlagenFirmcomp">
            <div className="content-desktop only-desktop">
                <div className="columns is-multiline">
                    {details.map((detail) => (
                        <Containers details={detail} />
                    ))}
                </div>
            </div>
            <div className="content-mobile only-mobile">
                {detailsM.map((detail) => (
                    <ContainersM detailsM={detail} />
                ))}
            </div>
            {/* <div className="content-desktop  crow">
                <h2>Referenzen Das sagen unsere Kunden.</h2>
                <div className="col33To100">
                    Text Text Text Text Text Text Texttexttexttexttexttext“
                    <br />
                    Thomas Pahnke <br />
                    Head of Repair Shops Expansion <br />
                    Miles Mobility
                </div>
                <div className="col33To100">
                    Text Text Text Text Text Text Texttexttexttexttexttext“
                    <br />
                    Thomas Pahnke <br />
                    Head of Repair Shops Expansion <br />
                    Miles Mobility
                </div>
                <div className="col33To100">
                    Text Text Text Text Text Text Texttexttexttexttexttext“
                    <br />
                    Thomas Pahnke <br />
                    Head of Repair Shops Expansion <br />
                    Miles Mobility
                </div>
            </div> */}
        </div>
    );
};

export default AlarmanlagenFirmcomp;
