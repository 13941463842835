import React from "react";
import AlarmanlagenFirmcomp from "../components/AlarmanlagenFirmcomp";
import Howto2steps from "../components/Howto2steps";
import SevenDaysBanner from "../components/SevenDaysBanner";
import Footer from "../components/Footer";
import Header from "../components/Header";
import DefaultHero from "../components/DefaultHero";
import { graphql, Link } from "gatsby";
const HeroElement = () => {
    return (
        <div>
            <div className="container has-text-centered">
                <div>
                    <div className="product homepage firma-hero-mt">
                        <h1
                            className="top"
                            // style={{ padding: "0px!important" }}
                        >
                            Professionelle Alarmanlagen für Ihre Firma
                        </h1>
                        <br />
                        {/* <a className="button preisrechner-btn preisrechner-btn-primary" onClick={handleClick}>Jetzt Rabatt sichern!
                                </a> */}
                        <span className="middle">
                            Sicher. Hochwertig. Professionell.
                        </span>

                        <div className="cta-btn ">
                            <Link
                                id="btn_bighero_preisberechnen"
                                className="button preisrechner-btn preisrechner-btn-primary"
                                to={`/preisrechner`}
                            >
                                Kostenlos Preis berechnen
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const alarmanlagenhaus = ({ data, pageContext }) => {
    console.log("data", data);
    console.log("pageContext", pageContext);
    const contextData = {
        title: data.site.siteMetadata.title,
        image: "/img/alarmgen-firm/Big Hero Alarmanlagen Firma.png",
        image768: "/img/alarmgen-firm/Big Hero Alarmanlagen Firma.png",
        image1024: "/img/alarmgen-firm/Big Hero Alarmanlagen Firma.png",
        image1216: "/img/alarmgen-firm/Big Hero Alarmanlagen Firma.png",
        image1408: "/img/alarmgen-firm/Big Hero Alarmanlagen Firma.png",
        showPriceCalculator: false,
        defaultCssClass: "default-hero-not-home",
        showHeroMobile: true,
        showBottom: true,
        showElement: <HeroElement />,
        onlyDesktop: "onlyDesktop",
    };
    return (
        <div>
            <Header
                page={data.site.siteMetadata.alamranlagenMetadata}
                siteMetadata={data.site.siteMetadata}
                contextData={{}}
            />
            <div className="section alarmlagen-Firm">
                <div className="hero default-hero">
                    <div>
                        <DefaultHero context={contextData} />
                    </div>
                </div>
            </div>

            {/* <div class="alarmgenFirmImages">
                <img src="/img/alarmgen-firm/Storage.jpg" alt="" />

                <img src="/img/alarmgen-firm/Storage.jpg" alt="" />

                <img src="/img/alarmgen-firm/Storage.jpg" alt="" />
            </div> */}
            <div
                class="FirmImagerow"
                style={{
                    boxSizing: "border-box",
                    padding: "20px",
                    marginBottom: "5rem",
                    marginTop: "2rem",
                }}
            >
                <h2
                    style={{
                        textAlign: "center",
                        marginBottom: "2rem",
                        marginTop: "2rem",
                        fontWeight: "700",
                    }}
                >
                    Zuverlässige Sicherheitskonzepte für jedes Gewerbe
                    <p className="FirmImageTextBottom">Ausgewählte Beispiele</p>
                </h2>
                <div class="FirmImagecolumn">
                    <img
                        src="/img/alarmgen-firm/Bureau.jpg"
                        alt=""
                        style={{ width: "100%" }}
                    />
                    <span>Büros</span>
                </div>
                <div class="FirmImagecolumn ">
                    <img
                        className="imageWidthless"
                        src="/img/alarmgen-firm/Storage.jpg"
                        alt=""
                        style={{ width: "100%" }}
                    />
                    <span>Lagerräume</span>
                </div>
                <div class="FirmImagecolumn">
                    <img
                        src="/img/alarmgen-firm/Store.jpg"
                        alt=""
                        style={{ width: "100%" }}
                    />
                    <span>Geschäfte</span>
                </div>
            </div>
            {/* <Alarmanlagencomp /> */}
            <AlarmanlagenFirmcomp />
            <div className="section how-to2">
                <div className="body">
                    <div
                        className="head-font"
                        style={{ paddingBottom: "0%!important" }}
                    >
                        <h2>Lassen Sie jetzt Ihre Firma absichern.</h2>
                        <Howto2steps />
                    </div>
                </div>
            </div>
            <SevenDaysBanner cssclassName="with-background" />
            <Footer />
        </div>
    );
};

export const query = graphql`
    query {
        site {
            siteMetadata {
                title
                keywords
                description
                headerImage
                ogTitle
                ogDescription
                image
                image768
                image1024
                image1216
                image1408
                alamranlagenMetadata {
                    title
                    keywords
                    description
                    ogTitle
                    ogDescription
                }
            }
        }
    }
`;
export default alarmanlagenhaus;
